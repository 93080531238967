<template>
    <div>
       <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh; ">
            <b-spinner variant="primary" label="Text Centered" />     
        </div>
        <div v-else>
           <table-brand :options="carBrand" @editForm="editForm"></table-brand>
        </div>
        <modal-brand :Brand="Brand" @refresh="refresh" ></modal-brand>
        <modal-brand-edit @refresh="refresh" :id="id" ></modal-brand-edit>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>

<script>
import ModalBrand from "@/views/component/Modal/ModalBrand/ModalBrand.vue"
import ModalBrandEdit from "@/views/component/Modal/ModalBrand/ModalBrandEdit.vue"
import tableBrand from "@/views/component/Table/tableBrand.vue"
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            tableBrand,
            ModalBrand,
            ModalBrandEdit,
        },
        data() {
            return {
                carBrand: [],
                Brand: [],
                showPreloader: false,
                id: undefined,
                fields: [
                    {key: 'id', label: 'ID'},
                    {key: 'name', label: 'Название'},
                    {key: 'description', label: 'Описания'},
                    {key: 'created_by_login', label: 'Создал'},
                    {key: 'is_active', label: 'Статус'},
                    {key: 'date_time', label: 'Дата'},
                ],
            }
        },
        mounted() {
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
        this.getCategories()
      },
        methods: {
            editForm(id) {
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal() {
            this.$bvModal.show('BrandEdit')
        },
        getCategories() {
            this.$http.get('/car-settings/brands')
            .then((res) => {
                this.Brand = res.data
            })
        },
        openFilter() {
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true){
                this.showPreloader = true
                this.$http
                .get(`${this.$route.name}`,{params: param}).then(res => {
                    this.carBrand = res.data
                    this.$store.commit('pageData/setdataCount', this.carBrand.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
            }else if(arrayRoutes.includes(this.$route.path) == false){
        this.$bvModal.show(this.$route.name + 'filter')
      }
        },
            refresh() {
            this.showPreloader = true
            this.$http
            .get('/car-settings/brands')
            .then(res => {
                this.carBrand = res.data
                this.$store.commit('pageData/setdataCount', this.carBrand.length)
                this.showPreloader = false
                this.$store.commit('REFRESH_DATA', false)
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Ошибка!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    }
                })
            })
        },
        sendToParent(tableData) {
            this.carBrand = tableData
            this.$store.commit('pageData/setdataCount', this.carBrand.length)
        },
        },
        computed: {
        fetchingNewData() {
            return this.$store.state.refresh.fetchingNewData
        }
        },
        watch: {
        fetchingNewData(val) {
            if(val) {
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.carBrand = res.data
                    this.$store.commit('pageData/setdataCount', this.carBrand.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }
    }
</script>