<template>
    <div>
      <b-modal 
       id="BrandEdit"
       title="Редактирование бренд" 
       size="s"
       hide-footer 
       no-enforce-focus 
       :no-close-on-backdrop="true" 
       @show="getBrand"
       >
           <b-row>
              <b-col cols="12" class="mt-1" >
                  <label for="">Бренд</label>
                  <b-input v-model="brandType.name" placeholder="Бренд"/>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12" class="mt-1" >
                  <label for="">Описание</label>
                  <b-input v-model="brandType.description" placeholder="Описание"/>
              </b-col>
          </b-row>
          <b-row>
              <b-col class="mt-1">
                  <b-form-checkbox v-model="brandType.is_active" class="custom-control-primary">
                      {{this.brandType.is_active == true ? "Активный" : 'Неактивный'}}
                  </b-form-checkbox>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12" class="d-flex justify-content-between">
                  <b-button
                  :disabled="submitButtonDisabled || !brandType.name"
                  class="mt-2 col-md-5"
                  variant="primary"
                  @click="editBobyType"
                  >
                      Изменить
                  </b-button>
                  <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                      Отмена
                  </b-button>
              </b-col>
          </b-row>
      </b-modal>
    </div>
  </template>
  
  <script>
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  export default {
    props: ['id'],
      components: {
          ToastificationContent
      },
      data() {
          return {
              submitButtonDisabled: false, 
              brandType: {
                  name: undefined,
                  description: undefined,
                  is_active: undefined,
              },
          }
      },
      methods: {
          closeModal() {
              this.$bvModal.hide('BrandEdit')
              this.submitButtonDisabled = false
              this.brandType.name = null
          },
          editBobyType() {
              this.submitButtonDisabled = true
              this.$http.patch(`/car-settings/brands/${this.id.id}`, {
                  name: this.brandType.name,
                  description: this.brandType.description,
                  is_active: this.brandType.is_active
              })
              .then(res  => {
                  this.$emit('refresh')
                  this.closeModal()
                  this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                          title: 'Бренд изменен!',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: `${res.data.message}`
                      },
                  })
              })
              .catch(err => {
                  this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                          title: 'Некорректные бренд',
                          icon: 'XIcon',
                          variant: 'danger',
                          text: err.response.data.errors,
                      },
                  })
              })
              .finally(() => {
                  this.submitButtonDisabled = false
              })
          },
                getBrand() {
                this.$http.get(`/car-settings/brands/${this.id.id}/edit`)
                .then(res => {
                    this.brandType = res.data
                    this.brandType.is_active = res.data.is_active === 1 ? true : false
                })
            },
      },
  }
  </script>
  
  <style>
  
  </style>