<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Дабавить бренд машина" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row>
                <b-col cols="12 mb-1">
                    <label>Имя бренд</label>
                    <b-input v-model="brandType.name" placeholder="Имя бренд"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                     <label>Описание</label>
                    <b-input v-model.trim="brandType.description" placeholder="Описание" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                        :disabled="submitButtonDisabled"
                        class='mt-2 col-md-5'
                        variant="primary"
                        @click="createBodyType">
                        Дабавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent
    },
    props: ['Brand'],
    data() {
        return {
            brandType:{
                name: null,
                description: null,
            },
            submitButtonDisabled: false,
            categoriesSelectDisadled: false,
        }
    },
    methods: {
        clearData() {
            this.brandType = {
                name: null,
                description: null,
                }
            },
            closeModal() {
                this.$bvModal.hide(this.$route.name + 'CreateModal')
            },
            createBodyType()  {
                this.submitButtonDisabled = true
                this.$http.post('/car-settings/brands', this.brandType)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Бренд создан!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.$emit('refresh')
                    this.clearData()
                    this.closeModal()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные бренд!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors, 
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
            },
        }
    }
</script>